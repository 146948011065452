
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import SiteLayoutSwitcher, {
  LayoutSwitcherProps,
} from '~/components/site/LayoutSwitcher.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import { numOr10k } from '~/assets/ts/utils/math'

export const ListHeaderWrapperProps = {
  showCount: {
    type: Boolean,
    default: true,
  },
  topBorder: {
    type: Boolean,
    default: true,
  },
  disableSearch: {
    type: Boolean,
  },
  /** used in other items that extend from this */
  disableSort: {
    type: Boolean,
  },
  customCountText: {
    type: String as PropType<TranslateResult>,
    default: undefined,
    validator(value: TranslateResult) {
      if (!value) return true
      return value.toString().includes('{n}')
    },
  },
  count: {
    type: Number,
    default: undefined,
  },
  disableFilters: {
    type: Boolean,
  },
  customQ: {
    type: String,
    default: 'alt-layout',
  },
  displaySwitcher: {
    type: Boolean,
  },
}

export default Vue.extend({
  name: 'ListHeaderWrapper',
  components: {
    LoadingElement,
    SiteLayoutSwitcher,
    HorizontalRule,
  },
  props: {
    ...ListHeaderWrapperProps,
    ...LayoutSwitcherProps,
    customCountText: {
      type: String as PropType<TranslateResult>,
      required: true,
      validator(value: TranslateResult) {
        if (!value) return true
        return value.toString().includes('{n}')
      },
    },
  },
  computed: {
    countDisplay(): TranslateResult {
      if (this.count === undefined) return ''
      return this.$tc(this.customCountText.toString(), this.count, {
        n: numOr10k(this.count),
      })
    },
  },
})
