
import Vue from 'vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import ToggleButton from '~/components/_general/ToggleButton.vue'

export default Vue.extend({
  name: 'SiteSeriesAlbumArtToggle',
  components: { ToggleButton, InlineIcon },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: Boolean,
    },
  },
})
