
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import ListHeaderWrapper, {
  ListHeaderWrapperProps,
} from '~/components/_general/ListHeaderWrapper.vue'
import { SermonRequestOptions, SermonSortOptions } from '~/apiclient/apisermons'
import SortSermonsElement from '~/components/sort/SermonsElement.vue'
import {
  SermonFilterCategories,
  SermonFilterSelection,
} from '~/assets/ts/utils/params'
import SearchInput from '~/components/_general/SearchInput.vue'
import FilterSermonsDropdown from '~/components/filter/SermonsDropdown.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import ToggleButton from '~/components/_general/ToggleButton.vue'

export default Vue.extend({
  name: 'SiteSermonListHeader',
  components: {
    ToggleButton,
    InlineIcon,
    FilterSermonsDropdown,
    SearchInput,
    ListHeaderWrapper,
    SortSermonsElement,
  },
  props: {
    ...ListHeaderWrapperProps,
    sort: {
      type: String as PropType<SermonSortOptions>,
      default: SermonSortOptions.Newest,
    },
    filters: {
      type: Array as PropType<SermonFilterSelection[]>,
      default: () => [],
    },
    sermonOptions: {
      type: Object as PropType<SermonRequestOptions>,
      required: true,
    },
    videoOnly: {
      type: Boolean,
    },
    customCountText: {
      type: String as PropType<TranslateResult>,
      default(): TranslateResult {
        return this.$t('{n} Sermon | {n} Sermons')
      },
    },
  },
  data() {
    return {
      searchQuery:
        this.filters.find((f) => f.category === SermonFilterCategories.Search)
          ?.value ?? '',
    }
  },
  methods: {
    search() {
      const category = SermonFilterCategories.Search
      const filters = this.filters.filter((f) => f.category !== category)
      if (this.searchQuery) {
        filters.push({
          value: this.searchQuery,
          category,
        })
      }
      this.setFilters(filters)
    },
    filtersUseMedia(filters: SermonFilterSelection[]) {
      return filters.some(
        (f: SermonFilterSelection) =>
          f.category === SermonFilterCategories.Media
      )
    },
    setFilters(filters: SermonFilterSelection[]) {
      if (this.filtersUseMedia(filters) && this.videoOnly) {
        this.$emit('swap', false)
      }
      this.$emit('filter', filters)
    },
    sortChanged(sort: SermonSortOptions) {
      this.$emit('sort', sort)
    },
    toggleVideo() {
      if (this.filtersUseMedia(this.filters) && !this.videoOnly) {
        this.$emit(
          'filter',
          this.filters.filter(
            (f: SermonFilterSelection) =>
              f.category !== SermonFilterCategories.Media
          )
        )
      }
      this.$emit('swap', !this.videoOnly)
    },
  },
})
