
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { SeriesDisplay } from '~/assets/ts/enums'
import { getListWithPlaceholders } from '~/assets/ts/utils/lists'
import ScrollList from '~/components/_general/ScrollList.vue'
import SeriesElement from '~/components/_general/SeriesElement.vue'
import {
  GetSeriesSortFromQs,
  SeriesSortOptions,
  SeriesSortQsPair,
} from '~/components/sort/SeriesElement.vue'
import SiteSeriesListHeader from '~/components/site/SeriesListHeader.vue'
import { ValuesMatch } from '~/assets/ts/utils/validation'
import { qsBool, qsValue, updateQs } from '~/assets/ts/utils/params'
import { SeriesRequestOptions } from '~/apiclient/apiseries'
import { Series } from '~/models/series'

import { SearchRequestOptions } from '~/apiclient/apisearch'
import SiteSeriesAlbumArtToggle from '~/components/site/series/AlbumArtToggle.vue'

export const SiteSeriesFilteredListProps = {
  seriesOptions: {
    type: Object as PropType<SeriesRequestOptions>,
    default() {
      return {} as SeriesRequestOptions
    },
  },
  searchOptions: {
    type: Object as PropType<SearchRequestOptions>,
    default: undefined,
  },
  removeStartSeries: {
    type: Number,
    default: 0,
  },
  noResultsText: {
    type: String as PropType<TranslateResult>,
    default: '',
  },
  infinite: {
    type: Boolean,
    default: true,
  },
  display: {
    type: Number as PropType<SeriesDisplay>,
    default: SeriesDisplay.Album,
  },
  displayAlt: {
    type: Number as PropType<SeriesDisplay>,
    default: SeriesDisplay.Extended,
  },
  placeholderCount: {
    type: Number,
    default: 5,
  },
  topBorder: {
    type: Boolean,
    default: true,
  },
  allowFiltering: {
    type: Boolean,
  },
  disableSearch: {
    type: Boolean,
  },
  disableSort: {
    type: Boolean,
  },
  storeInQs: {
    type: Boolean,
    default: true,
  },
  async: {
    type: Boolean,
  },
  buffer: {
    type: Number,
    default: 700,
  },
  showCount: {
    type: Boolean,
    default: true,
  },
  customCountText: {
    type: String as PropType<TranslateResult>,
    default: undefined,
    validator(value: TranslateResult) {
      if (!value) return true
      return value.toString().includes('{n}')
    },
  },
  small: {
    type: Boolean,
  },
  defaultSort: {
    type: String as PropType<SeriesSortOptions>,
    default: SeriesSortOptions.Newest,
  },
  scrollListClasses: {
    type: String,
    default: '',
  },
  absoluteUrls: {
    type: Boolean,
  },
  broadcasterID: {
    type: String,
    default: undefined,
  },
  includeUpdatedDate: {
    type: Boolean,
  },
  includeBroadcaster: {
    type: Boolean,
    default: true,
  },
  twoCol: {
    type: Boolean,
  },
}

export default Vue.extend({
  name: 'SiteFilteredSeriesList',
  components: {
    SiteSeriesAlbumArtToggle,
    SeriesElement,
    ScrollList,
    SiteSeriesListHeader,
  },
  props: {
    ...SiteSeriesFilteredListProps,
    noResultsText: {
      type: String as PropType<TranslateResult>,
      default(): TranslateResult {
        return this.$t('No Series Found')
      },
    },
  },
  data() {
    const useQs = this.storeInQs && this.allowFiltering
    return {
      seriesPojo: [] as Record<string, any>[],
      pageNum: this.seriesOptions.page ?? 1,
      totalCount: undefined as number | undefined,
      optionsReset: false,
      sort: useQs
        ? GetSeriesSortFromQs(this, this.defaultSort)
        : this.defaultSort,
      searchKeyword: useQs ? (qsValue(this, 'q') ?? '') : '',
      next: undefined as string | null | undefined,
      fetching: false,
      useAltLayout: qsBool(this, 'alt-layout'),
      requireSeriesAlbumArt: false,
    }
  },
  async fetch() {
    if (this.async) return
    await this.getDetails()
  },
  computed: {
    showArtToggle(): boolean {
      return this.seriesOptions.onlyWithAlbumArt === undefined
    },
    wrapperClasses(): string {
      return this.twoCol
        ? 'series-grid-list'
        : this.useListLayout
          ? ''
          : 'series-container'
    },
    useListLayout(): boolean {
      let layout
      if (this.useAltLayout) {
        layout = this.displayAlt
      } else {
        layout = this.display
      }

      return (
        layout === SeriesDisplay.Standard || layout === SeriesDisplay.Extended
      )
    },
    useQs(): boolean {
      return this.storeInQs && this.allowFiltering
    },
    noResultsTextOrDefault(): TranslateResult {
      return (
        this.noResultsText ?? this.$t('No series found with the current search')
      )
    },
    chosenDisplay(): SeriesDisplay {
      return this.useAltLayout ? this.displayAlt : this.display
    },
    filterAndSortOptions(): SeriesRequestOptions {
      const sortBy = this.disableSort ? undefined : this.sort
      const options = {
        ...{
          onlyWithAlbumArt: this.requireSeriesAlbumArt,
          sort_by: sortBy,
        },
        ...this.seriesOptions,
      } as SeriesRequestOptions

      if (this.searchKeyword) {
        options.searchKeyword = this.searchKeyword
      }

      return options
    },
    maxListLength(): number | undefined {
      const pageSize = this.filterAndSortOptions?.pageSize
      return !this.infinite && pageSize ? pageSize : undefined
    },
    series(): (Series | undefined)[] {
      return getListWithPlaceholders(
        this.seriesPojo
          .map((s: Record<string, any>) => new Series(s))
          .slice(this.removeStartSeries),
        this.placeholderCount
      )
    },
    endOfList(): boolean {
      if (this.next === null) return true
      if (this.optionsReset || this.totalCount === undefined) return false
      return this.seriesPojo.length >= this.totalCount
    },
  },
  watch: {
    filterAndSortOptions: {
      handler(newValue: SeriesRequestOptions, oldValue: SeriesRequestOptions) {
        if (ValuesMatch(newValue, oldValue)) return
        this.seriesOptionsChanged()
        if (oldValue.sort_by !== newValue.sort_by) {
          this.$emit('sortChanged', newValue.sort_by)
        }
      },
    },
    broadcasterID() {
      this.seriesOptionsChanged()
    },
  },
  mounted() {
    if (!this.async) return
    this.getDetails()
  },
  methods: {
    async getDetails() {
      await this.getSeries()
    },
    seriesOptionsChanged() {
      this.optionsReset = true
      this.seriesPojo = []
      this.next = undefined
      this.pageNum = this.seriesOptions.page ?? 1
      this.updateQs()
      this.getSeries()
    },
    updateQs() {
      if (!this.useQs) return
      const list = []
      const sort = SeriesSortQsPair(this.sort)
      if (sort.value !== this.defaultSort) {
        list.push(sort)
      }
      // TODO: change this based on new search support
      if (this.searchKeyword) {
        list.push({ key: 'q', value: this.searchKeyword })
      }
      updateQs(this, list)
    },
    async getSeries() {
      if (this.endOfList || this.fetching) return
      this.fetching = true
      let fresults, ftotalCount, fnext
      if (this.searchOptions) {
        const { seriesResults, next } = await this.$apiClient.getMultiSearch({
          ...this.searchOptions,
          page: this.pageNum,
        })
        fresults = seriesResults ?? []
        fnext = next
      } else {
        const { results, totalCount, next } =
          await this.$apiClient.getSeriesList({
            ...this.filterAndSortOptions,
            broadcasterID: this.broadcasterID,
            page: this.pageNum,
          })
        fresults = results
        ftotalCount = totalCount
        fnext = next
      }

      this.totalCount = ftotalCount
      this.$emit('seriesCount', ftotalCount)
      this.optionsReset = false
      this.next = fnext
      this.seriesPojo = this.seriesPojo.concat(fresults)
      this.pageNum++
      this.fetching = false
      if (this.next === null) {
        this.totalCount = this.seriesPojo.length
      }
    },
  },
})
